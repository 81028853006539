<template>
  <v-container class="di-hero">
    <v-row>
      <v-col cols="12" sm="6" md="5" xl="5"
             class="di-hero-left d-flex flex-column justify-center"
             
      >
        <h1>Dickinson Marx</h1>

        <img class="logo" src="../assets/logo_wave.svg" alt="logo">

        <p>Specialising in a wide variety of legal services, providing our clients with a innovative, sensible and credible strategy.</p>

        <div class="d-flex align-items justify-center">

          <v-spacer></v-spacer>

          <v-btn color="accent darken-1"
                 class="di-btn mr-auto d-flex align-items"
                 @click="$emit('link-clicked', servicesLinkId)"
          >
            Our Services
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

        </div>

      </v-col>

      <v-col cols="12" sm="6" md="7" xl="7"
             class="d-flex justify-center align-center"
      >
        <div class="grid-container">
          <div class="blank blank-1"></div>
          <div class="blank blank-2"></div>
          <div class="blank blank-3"></div>
          <div class="blank blank-4"></div>
          <div class="blank blank-5"></div>
          <div class="blank blank-6"></div>
          <div class="blank blank-7"></div>
          <div class="blank blank-8"></div>
          <div class="blank blank-9"></div>
          <div class="blank blank-10"></div>
          <div class="blank blank-11"></div>

          <!-- <div class="logo">
            <img src="../assets/logo.svg" alt="logo">
          </div> -->

          <div class="circle circle-top">
            <img src="../assets/vision_illustration.svg" alt="logo">
          </div>
          <div class="circle circle-left">
            <img src="../assets/mission_illustration.svg" alt="logo">
          </div>
          <div class="circle circle-right">
            <img src="../assets/values_illustration.svg" alt="logo">
          </div>
          
          <!-- <v-btn class="play play-left" color="accent">
            <v-icon left>mdi-play-circle-outline</v-icon>
            Play Video
          </v-btn> -->
          <!-- <v-btn class="play play-right" color="accent">
            <v-icon left>mdi-play-circle-outline</v-icon>
            Play Video
          </v-btn> -->

          <!-- <div class="play play-left d-flex justify-center align-center">
            <v-btn text>
              <v-icon left>mdi-play-circle-outline</v-icon>
              Play Video
            </v-btn>
          </div>
          <div class="play play-right d-flex justify-center align-center">
            <v-btn text>
              <v-icon left>mdi-play-circle-outline</v-icon>
              Play Video
            </v-btn>
          </div> -->
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'di-hero',

    props: {
      links: { type: Array, required: true },
    },

    computed: {
      servicesLinkId: function () {
        var link = this.links.find(link => link.name === 'Services')
        if (!link) {
          return -1;
        }

        return link.id;
      },
    },
  }
</script>

<style lang="scss">

$-size: 1em;

.di-hero {

  margin-bottom: 5em;

  position: relative;
  top: 2em;

  text-align: center;

  .di-hero-left {
    gap: 1.25em;
    text-align: center;
  }

  h1 {
    font-family: 'epicursive';
    font-size: 3em;
    text-transform: capitalize !important;
  }

  h2 {
    font-size: 1.5em;
    text-transform: uppercase;
  }

  p {
    font-size: 1.25em;
  }

  .grid-container {
    height: calc(40 * #{$-size});
    padding: calc(1 * #{$-size});

    transform: scale(50%);

    display: grid;
    justify-content: center;
    align-items: center;

    grid-template-areas:
      'a a a a x x b b b b'
      'a a a a x x b b b b'
      'a a a a c c b b b b'
      'h y y i c c l z z m'
      'h y y i c c l z z m'
      'd d d d c c e e e e'
      'j j j j c c k k k k'
      'f f f f c c g g g g';

    &::before {
      content: '';
      z-index: 1;
      margin-top: calc(10 * #{$-size});
      margin-left: calc(8 * #{$-size});
      height: calc(36 * #{$-size});
      width: calc(19 * #{$-size});
      position: absolute;

      // background-color: var(--v-white-base);
      background-color: var(--v-hero-light-purple-base);

      border: 1px solid var(--v-di-border-color-base);
      border-radius: 1em;
      box-shadow: 0 calc(0.3 * #{$-size}) var(--v-di-border-color-base);

      background-image: url('../assets/hero_illustration.svg');
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &::after {
      content: '';
      z-index: 0;
      height: calc(38 * #{$-size});
      width: calc(38 * #{$-size});
      position: absolute;

      background: var(--v-accent-lighten5);
      background-image: radial-gradient(circle at 11% 32%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 5%,transparent 5%, transparent 95%),radial-gradient(circle at 89% 30%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 5%,transparent 5%, transparent 95%),radial-gradient(circle at 76% 90%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 6%,transparent 6%, transparent 94%),radial-gradient(circle at 83% 14%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 6%,transparent 6%, transparent 94%),radial-gradient(circle at 62% 81%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 6%,transparent 6%, transparent 94%),radial-gradient(circle at 34% 3%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 6%,transparent 6%, transparent 94%),radial-gradient(circle at 19% 1%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 4%,transparent 4%, transparent 96%),radial-gradient(circle at 48% 14%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 4%,transparent 4%, transparent 96%),radial-gradient(circle at 54% 23%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 4%,transparent 4%, transparent 96%),radial-gradient(circle at 10% 37%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 4%,transparent 4%, transparent 96%),linear-gradient(271deg, rgb(6,209,97),rgb(132,64,248));

      border-radius: 50%;
      align-self: center;
      justify-self: center;
      margin-bottom: calc(6 * #{$-size});
      // opacity: 0.2;
    }

    .blank {

      &.blank-1 {
        grid-area: a;
      }

      &.blank-2 {
        grid-area: b;
      }

      &.blank-3 {
        grid-area: c;
      }

      &.blank-4 {
        grid-area: d;
        height: calc(6 * #{$-size});
      }

      &.blank-5 {
        grid-area: e;
        height: calc(6 * #{$-size});
      }

      &.blank-6 {
        grid-area: f;
      }

      &.blank-7 {
        grid-area: g;
      }

      &.blank-8 {
        grid-area: h;
      }

      &.blank-9 {
        grid-area: i;
      }

      &.blank-10 {
        grid-area: l;
      }

      &.blank-11 {
        grid-area: m;
      }
    }

    .circle {
      z-index: 2;
      height: calc(11 * #{$-size});
      width: calc(11 * #{$-size});
      
      background-color: var(--v-white-base);
      // background-color: var(--v-hero-light-purple-base);

      border: 1px solid var(--v-di-border-color-base);
      border-radius: 50%;
      box-shadow: 0 calc(0.3 * #{$-size}) var(--v-di-border-color-base);

      position: relative;

      overflow: hidden;

      img {
        margin-left: calc(0.25 * #{$-size});
        margin-top: calc(0.25 * #{$-size});
        height: calc(10 * #{$-size});
        width: calc(10 * #{$-size});
      }

      &.circle-top {
        grid-area: x;
        align-self: start;
      }
      
      &.circle-left {
        grid-area: y;
        justify-self: end;
      }
      
      &.circle-right {
        grid-area: z;
        justify-self: start;
      }
    }

    .play {
      z-index: 2;
      height: calc(4 * #{$-size});
      width: calc(12 * #{$-size});
      background-color: #fff;
      
      border: 1px solid var(--v-di-border-color-base);
      border-radius: 0.625em;
      box-shadow: 0 calc(0.3 * #{$-size}) var(--v-di-border-color-base);

      &.play-left {
        grid-area: k;
      }
      
      &.play-right {
        grid-area: j;
      }
    }
  }

}

@media screen and (min-width: 600px) {
  .di-hero {
    // min-height: 70vh;
    top: 0em;

    text-align: left;

    .grid-container {
      transform: scale(60%);
    }
  }
}

@media screen and (min-width: 800px) {
  .di-hero {
    // min-height: 85vh;
    top: 0em;

    text-align: left;

    .grid-container {
      transform: scale(70%);
    }
  }
}

@media screen and (min-width: 1264px ) {
  .di-hero {
    .grid-container {
      transform: scale(80%);
    }
  }
}

@media screen and (min-width: 1900px) {
  .di-hero {
    top: 5em;
    // min-height: 95vh;
    width: 80%;

    .di-hero-left {
      gap: 2.5em;

      h1 {
        font-size: 5em;
      }

      h2 {
        font-size: 2.5em;
      }

      p {
        font-size: 1.25em;
      }
    }

    .grid-container {
      transform: scale(95%);
    }
  }
}
</style>
