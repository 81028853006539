<template>
  <v-container class="di-services" style="position: relative;">

    <v-row>
      <v-col v-if="!$vuetify.breakpoint.mobile"></v-col>
      <v-col :md="!$vuetify.breakpoint.mobile ? 10 : 12">
            
        <h1 class="di-section-header"> Services </h1>

        <v-row>

          <template v-for="(service, i) in services">
            <v-col :key="i" cols="12" xl="4" md="6" class="d-flex flex-column">
              <v-card class="di-card mb-1 flex-grow-1 d-flex flex-column">
                <v-card-text class="flex-grow-1">
                  <div class="d-flex di-card-content" style="gap: 2rem;">
                    <v-img :src="require(`../assets/service/${service.img}`)"
                            :alt="'service-' + service.title"
                            width="100" height="100"
                            class="flex-grow-0"
                    />

                    <div class="d-flex flex-column">
                      <div class="text-h6">{{ service.title }}</div>

                      <p class="pt-3">
                        {{ service.description }}
                      </p>
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions class="mb-2">
                  <v-spacer></v-spacer>
                  <v-btn text color="accent"
                          @click="showDialog(service)">
                    Learn More
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                  <v-spacer v-if="$vuetify.breakpoint.mobile"></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>

            <!-- <v-col :key="i" cols="12" md="6" class="d-flex flex-column">
              <v-card class="di-card mb-1 flex-grow-1 d-flex flex-column">
                <v-card-title>
                  {{ service.title }}
                </v-card-title>
                <v-card-text class="flex-grow-1">
                  <p>
                    {{ service.description }}
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="accent"
                          @click="showDialog(service)">
                    Learn More
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col> -->

          </template>

        </v-row>
        
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.mobile"></v-col>
    </v-row>

    <v-dialog v-model="dialog" :width="$vuetify.breakpoint.mobile? '100%' : '75%'">
      <v-card v-if="openService" class="text-left">
        <v-card-title>
          {{ openService.title }}
        </v-card-title>
        <v-card-text>
          <p>
            {{ (openService.detail ? openService.detail.description : openService.description) }}
          </p>
          
          <ul v-if="openService.detail && openService.detail.items">
            <li v-for="(item, i) in openService.detail.items" :key="i">
              {{ item }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="accent"
                 @click="closeDialog">
            Close
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import services from '../assets/data/services.json'


export default {
  name: 'di-services',

  data: () => ({
    services: services,

    dialog: false,
    openService: null,
  }),
  
  methods: {
    showDialog (item) {
      this.openService = item;
      this.dialog = true;
    },
    closeDialog () {
      this.dialog = false;
      this.openService = null;
    },
  }
}
</script>

<style lang="scss">

$-size: 1em;

.di-services {

  .di-card-content {
    flex-direction: column;
    align-items: center;
  }

  .v-image__image {
    background-size: 65% !important;
  }
  
  @media screen and (min-width: 800px) {
    
    .di-card-content {
      flex-direction: row;
    }
  }
}
</style>
