<template>
  <v-app id="di-app">
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  name: "di-app",
}
</script>

<style lang="scss">

$-size: 1em;

body {
  background-color: var(--v-background-lighten1);
  text-align: center;
}

#di-app {
  font-family: 'Calibri';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1, h2 {
    text-transform: uppercase;
  }

  h1 {
    margin-bottom: 0.5rem;
  }

  h3 {
    margin-top: 1.25rem;

    text-transform: capitalize;
    font-style: italic;
  }

  .di-notification {
    height: 2.5em;
    width: 100%;
    top: 0;
    left: 0;
    background-color: var(--v-black-base);
    position: absolute;

    border-radius: 0 0 0.625em 0.625em;
    
    background: rgb(140,103,198);
    background-image: radial-gradient(circle at 11% 32%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 5%,transparent 5%, transparent 95%),radial-gradient(circle at 89% 30%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 5%,transparent 5%, transparent 95%),radial-gradient(circle at 76% 90%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 6%,transparent 6%, transparent 94%),radial-gradient(circle at 83% 14%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 6%,transparent 6%, transparent 94%),radial-gradient(circle at 62% 81%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 6%,transparent 6%, transparent 94%),radial-gradient(circle at 34% 3%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 6%,transparent 6%, transparent 94%),radial-gradient(circle at 19% 1%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 4%,transparent 4%, transparent 96%),radial-gradient(circle at 48% 14%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 4%,transparent 4%, transparent 96%),radial-gradient(circle at 54% 23%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 4%,transparent 4%, transparent 96%),radial-gradient(circle at 10% 37%, rgba(255,255,255, 0.0392156862745098) 0%, rgba(255,255,255, 0.0392156862745098) 4%,transparent 4%, transparent 96%),linear-gradient(271deg, rgb(6,209,97),rgb(132,64,248));

    span {
      color: var(--v-white-base);
    }

    .circle {
      position: relative;
      height: 5em;
      width: 5em;

      border-radius: 50%;

      background-color: transparent;

      border: 1px solid var(--v-background-base);
      box-shadow: 0 0.25em var(--v-background-base);
    }

    .circles-left {
      :nth-child(1) {
        left: 0.5em;
        top: -1.75em;
      }

      :nth-child(2) {
        left: -2.5em;
        top: -2em;
      }

      :nth-child(3){
        left: -5em;
        top: -2.5em;
      }
    }
    
    .circles-right {
      :nth-child(3) {
        right: 0.5em;
        top: -1.75em;
      }

      :nth-child(2) {
        right: -2.5em;
        top: -2em;
      }

      :nth-child(1){
        right: -5em;
        top: -2.5em; 
      }
    }
  }

  .di-nav {
    .di-main-tabs {
      .v-tab {
        font-weight: 600;
      }
    }
  }

  .di-main {
    background-color: var(--v-background-lighten1);
    
    background: repeating-linear-gradient(
      to right,
      var(--v-background-base) 0px,
      var(--v-background-base) 1px,
      var(--v-background-lighten1) 2px,
      var(--v-background-lighten1) 100px,
    );
  }

  .di-section {
    margin-bottom: 5rem;

    .di-section-header {
      font-size: 3em;
      opacity: 0.2;
      overflow: hidden;
    }
    
    &:nth-child(1) {
      margin-bottom: 0;
    }
  }

  .di-card {
    border: 1px solid var(--v-di-border-color-base) !important;
    border-radius: 1em;
    box-shadow: 0.05em calc(0.25em) var(--v-di-border-color-base);
  }

  .di-btn {
    border: 1px solid var(--v-di-border-color-base) !important;
    box-shadow: 1px 3px var(--v-di-border-color-base);
  }
  
  .v-image__image {
    border: 1px solid var(--v-di-border-color-base);
    border-radius: 50%;
    box-shadow: 0 calc(0.3 * #{$-size}) var(--v-di-border-color-base);

    background-color: var(--v-hero-light-purple-base);

    transform: scale(0.9);

  }

}

@media screen and (min-width: 600px) {
}

@media screen and (min-width: 800px) {
  body {
    text-align: left;
  }

  #di-app {
    .di-section-header {
      font-size: 6em;
    }
  }
}

@media screen and (min-width: 1264px ) {
  #di-app {
    .di-section {
      &:nth-child(1) {
        margin-bottom: 5rem;
      }
    }
  }
}

@media screen and (min-width: 1900px) {
  #di-app {
    .di-section {
      &:nth-child(1) {
        margin-bottom: 10rem;
      }
    }
  }
}

/* custom scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--v-di-scroll-bar-color-base) var(--v-di-scroll-bar-bg-color-base);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--v-di-scroll-bar-bg-color-base);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--v-di-scroll-bar-color-base);
  border-radius: 20px;
  border: 3px solid var(--v-di-scroll-bar-bg-color-base);
}
</style>