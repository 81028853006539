<template>
    <div class="di-home">
        
        <v-app-bar app fixed dense>
        <template v-slot:img="{ }">
            <div class="di-notification d-flex"
                :class="{ 'justify-space-between': !$vuetify.breakpoint.mobile, 'justify-space-around': $vuetify.breakpoint.mobile }">
            <div class="circles-left d-flex align-center">
                <div v-for="index in 3" :key="index"
                    class="circle"></div>
            </div>

            <span class="align-self-center"></span>

            <div class="circles-right d-flex align-center">
                <div v-for="index in 3" :key="index"
                    class="circle"></div>
            </div>
            </div>
        </template>

        <!-- <v-toolbar-title>Title</v-toolbar-title> -->

        <template v-slot:extension>
            <v-tabs v-if="!$vuetify.breakpoint.mobile"
                    v-model="group"
                    centered color="primary darken-2" class="di-main-tabs">
            <v-tab v-for="link in links" :key="'tab_' + link.id"
                    :id="'tab_' + link.id"
                    @click="scrollTo(link)">
                {{ link.name }}
            </v-tab>
            </v-tabs>

            <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile"
                                @click.stop="drawer = !drawer">
            </v-app-bar-nav-icon>

            <v-navigation-drawer v-if="$vuetify.breakpoint.mobile"
                                v-model="drawer" absolute app hide-overlay
                                style="width: 100%; height: 100vh;"
            >
            <!-- close icon -->
            <div class="d-flex justify-end align-center">

                <v-spacer></v-spacer>

                <v-btn color="accent" icon
                    @click="drawer = !drawer">
                <v-icon>mdi-close</v-icon>
                </v-btn>

            </div>

            <v-divider></v-divider>

            <!-- menu list -->
            <v-list
                nav
                dense
            >
                <v-list-item-group
                v-model="group"
                >
                <v-list-item v-for="link in links" :key="'link_' + link.id" :id="'link_' + link.id"
                            class="text-left"
                            @click="scrollTo(link)">
                    <v-list-item-icon>
                    <v-icon>{{ link.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> {{ link.name }} </v-list-item-title>
                </v-list-item>
                </v-list-item-group>
            </v-list>
            </v-navigation-drawer>

            <v-spacer></v-spacer>

            <div class="d-flex justify-end align-center">

            <v-spacer></v-spacer>

            <!-- <v-switch
                v-model="$vuetify.theme.dark" hide-details color="white"
                hint="This toggles the global state of the Vuetify theme"
                inset
                style="z-index: 10;"
            ></v-switch> -->

            <v-btn color="accent" icon
                    @click="$vuetify.theme.dark = !$vuetify.theme.dark">
                <v-icon>mdi-theme-light-dark</v-icon>
            </v-btn>

            <!-- <v-btn color="accent" icon>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-btn color="accent" icon>
                <v-icon>mdi-heart</v-icon>
            </v-btn> -->

            <!-- <v-menu
                bottom
                left
            >
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    color="accent"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                </template>

                <v-list>
                <v-list-item>
                    <v-list-item-title>Click Me 1</v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title>Click Me 2</v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title>Click Me 3</v-list-item-title>
                </v-list-item>

                <v-list-item>
                    <v-list-item-title>Click Me 4</v-list-item-title>
                </v-list-item>
                </v-list>
            </v-menu> -->

            </div>
        </template>
        </v-app-bar>

        <v-main class="di-main">
        <hero id="section-home"
                :links="links" @link-clicked="setGroup"
                class="di-section">
        </hero>
        <services id="section-services"
                    class="di-section">
        </services>
        <about-us id="section-about-us"
                    class="di-section">
        </about-us>
        <contact-us id="section-contact-us"
                    class="di-section"
                    :contact-methods="contactMethods"
                    @open-social="openSocial">
        </contact-us>
        </v-main>

        <v-footer padless class="text-center">
        <v-card
            flat
            tile
            width="100%"
        >
            <v-card-text>
            <div class="d-flex align-items justify-center m-auto text-center">
                <v-btn text color="accent" :class="{ 'text--darken-3': !$vuetify.theme.dark }"
                    @click="showTerms = true">
                Terms and Conditions
                </v-btn>

                <v-divider vertical class="mx-2"></v-divider>

                <v-btn text color="accent" :class="{ 'text--darken-3': !$vuetify.theme.dark }"
                    @click="showPolicy = true">
                Privacy Policy
                </v-btn>
            </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text>
            {{ new Date().getFullYear() }} — Stack Rabbit Innovations
            </v-card-text>
        </v-card>
        </v-footer>

        <!-- FAB -->
        <v-speed-dial v-model="fab"
                    fixed bottom right
                    direction="top" transition="slide-y-reverse-transition"
        >
        <template v-slot:activator>
            <v-btn
            v-model="fab"
            color="accent"
            fab
            >
            <v-icon>
                mdi-dots-vertical
            </v-icon>
            </v-btn>
        </template>

        <v-btn v-for="(contact, i) in contactMethods.slice().reverse()" :key="i"
                fab small color="primary"
                @click="openSocial(contact.value, contact.target)">
            <v-icon>{{ contact.icon }}</v-icon>
        </v-btn>
        </v-speed-dial>

        <v-dialog v-model="showTerms" :width="$vuetify.breakpoint.mobile? '100%' : '75%'">
        <v-card class="text-left">
            <v-card-title>
            Terms and Conditions
            </v-card-title>
            <v-card-text>
            <h3>1. Introduction</h3>
            <p>These terms and conditions govern your use of our website. By accessing and using our website, you agree to comply with these terms and conditions in full. If you do not agree with any part of these terms and conditions, please refrain from using our website.</p>

            <h3>2. Intellectual Property</h3>
            <p>The content and materials on our website, including but not limited to text, graphics, logos, images, audio clips, and software, are owned by or licensed to us and are protected by intellectual property laws. You may not reproduce, modify, distribute, or republish any content from our website without our prior written consent.</p>

            <h3>3. User Conduct</h3>
            <p>When using our website, you agree to abide by the following rules of conduct:</p>
            <ul>
                <li>Do not engage in any unlawful, fraudulent, or harmful activities.</li>
                <li>Do not upload or transmit any material that is unlawful, obscene, defamatory, or infringing on the rights of others.</li>
                <li>Do not attempt to gain unauthorized access to our website or its systems.</li>
                <li>Do not interfere with the proper functioning of our website.</li>
                <li>Respect the privacy of others and do not collect or disclose their personal information without consent.</li>
            </ul>

            <h3>4. Privacy Policy</h3>
            <p>We are committed to protecting your privacy. Please review our Privacy Policy to understand how we collect, use, and disclose personal information.</p>

            <h3>5. Limitation of Liability</h3>
            <p>We strive to provide accurate and up-to-date information on our website, but we make no representations or warranties of any kind, express or implied, regarding the accuracy, reliability, or completeness of the content. We shall not be liable for any damages or losses arising out of your use of our website.</p>

            <h3>6. Links to Third-Party Websites</h3>
            <p>Our website may contain links to third-party websites for your convenience. We do not endorse or assume any responsibility for the content, products, or services provided by these third-party websites. Your use of third-party websites is at your own risk.</p>

            <h3>7. Indemnification</h3>
            <p>You agree to indemnify and hold us harmless from any claims, damages, liabilities, or expenses arising out of your use of our website or any violation of these terms and conditions.</p>

            <h3>8. Modifications and Termination</h3>
            <p>We reserve the right to modify, suspend, or terminate our website or any part thereof at any time without prior notice. We may also update these terms and conditions from time to time. By continuing to use our website after the modifications, you agree to be bound by the updated terms and conditions.</p>

            <h3>9. Governing Law</h3>
            <p>These terms and conditions shall be governed by and construed in accordance with the laws of Republic of South Africa. Any disputes arising out of these terms and conditions or your use of our website shall be subject to the exclusive jurisdiction of the courts of Republic of South Africa.</p>

            <h3>10. Contact Us</h3>
            <p>If you have any questions or concerns regarding these terms and conditions, please contact us at info@dickinson-inc.co.za.</p>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="accent"
                    @click="showTerms = false">
                Close
                <v-icon right>mdi-close</v-icon>
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
        
        <v-dialog v-model="showPolicy" :width="$vuetify.breakpoint.mobile? '100%' : '75%'">
        <v-card class="text-left">
            <v-card-title>
            Privacy Policy
            </v-card-title>
            <v-card-text>
            <p>This Privacy Policy explains how we collect, use, and disclose your personal information when you visit and interact with our website. We are committed to protecting your privacy and ensuring the security of your personal information. By using our website, you consent to the terms of this Privacy Policy.</p>

            <h3>Information We Collect</h3>
            <h4>1.1 Personal Information:</h4>
            <p>When you visit our website, we may collect personal information that you voluntarily provide to us, such as your name, email address, phone number, or any other information you provide when filling out forms or contacting us.</p>

            <h4>1.2 Usage Information:</h4>
            <p>We may automatically collect certain information about your visit to our website, including your IP address, browser type, operating system, referring website, pages visited, and the date and time of your visit. This information is used for analytical purposes and to improve the functionality and performance of our website.</p>

            <h3>Use of Information</h3>
            <h4>2.1 Personal Information:</h4>
            <p>We may use your personal information to:</p>
            <ul>
                <li>Respond to your inquiries and provide the information or services you request.</li>
                <li>Send you important notices, updates, or newsletters.</li>
                <li>Personalize your experience on our website.</li>
                <li>Improve our products, services, and website based on your feedback.</li>
                <li>Communicate with you about promotions, offers, or new products and services.</li>
            </ul>

            <h4>2.2 Usage Information:</h4>
            <p>We may use usage information to analyze trends, administer the website, track users' movements, and gather demographic information for aggregate use. This helps us understand how visitors use our website and enables us to enhance user experience.</p>

            <h3>Disclosure of Information</h3>
            <p>We may disclose your personal information:</p>
            <ul>
                <li>To trusted third-party service providers who assist us in operating our website, conducting our business, or providing services to you.</li>
                <li>In response to a legal request or when required by law to comply with applicable laws, regulations, or legal processes.</li>
                <li>To protect our rights, privacy, safety, or property, or that of our users or the public.</li>
                <li>In connection with a business transaction, such as a merger, acquisition, or sale of assets, where personal information may be transferred as part of the transaction.</li>
            </ul>
            <p>We do not sell, rent, or lease your personal information to third parties unless we have your permission or are required by law to do so.</p>

            <h3>Data Security</h3>
            <p>We take reasonable measures to protect the confidentiality, integrity, and security of your personal information. However, please note that no method of transmission over the Internet or electronic storage is completely secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

            <h3>Third-Party Links</h3>
            <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of such websites. We encourage you to review the privacy policies of these third-party sites before providing any personal information.</p>

            <h3>Children's Privacy</h3>
            <p>Our website is not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected personal information from a child under the age of 13, we will promptly take steps to delete such information.</p>

            <h3>Your Rights</h3>
            <p>You have the right to access, update, correct, and delete your personal information. If you wish to exercise any of these rights or have any questions or concerns about our Privacy Policy, please contact us using the information provided below.</p>

            <h3>Changes to the Privacy Policy</h3>
            <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on our website. We encourage you to review this Privacy Policy periodically for any updates.</p>

            <h3>Contact Us</h3>
            <p>If you have any questions or concerns about our Privacy Policy or our privacy practices, please contact us at info@dickinson-inc.co.za. We will make every effort to address your inquiries and resolve any concerns.</p>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="accent"
                    @click="showPolicy = false">
                Close
                <v-icon right>mdi-close</v-icon>
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
        
    </div>
</template>

<script>

import contacts from '../assets/data/contacts.json'

import hero from '../components/hero.vue';
import services from '../components/services.vue';
import aboutUs from '../components/about-us.vue';
import contactUs from '../components/contact-us.vue';

export default {
    name: 'di-home',
    components: {
        hero,
        services,
        aboutUs,
        contactUs
    },

    data: () => ({
        links: [
            { id: 0, url: 'section-home', icon: 'mdi-home-outline', name: 'Home' },
            { id: 1, url: 'section-services', icon: 'mdi-hand-extended-outline', name: 'Services' },
            { id: 2, url: 'section-about-us', icon: 'mdi-card-account-details-outline', name: 'About Us' },
            { id: 3, url: 'section-contact-us', icon: 'mdi-email-outline', name: 'Contact Us' },
        ],
            
        contactMethods: contacts,

        drawer: false,
        group: null,

        fab: false,

        showTerms: false,
        showPolicy: false,
    }),

    methods: {
            
        scrollTo (link) {
            if (!link) {
                console.warn(`Invalid link; link: ${link}`);
                return;
            }

            const element = document.getElementById(link.url);
            if (!element) {
                console.warn(`Element with id ${link.url} not found`);
                return;
            }

            const position = element.offsetTop + (link.id == 0 ? -100 : 0);
                
            window.scrollTo({ top: position, behavior: "smooth" });
        },

        setGroup (linkId) {

            if (linkId == null || linkId == undefined) {
                console.warn(`Invalid link id; linkId: ${linkId}; group: ${this.group}`);
                return;
            }

            var link = this.links.find(link => link.id == linkId);
            if (!link) {
                console.warn(`Link with id ${linkId} not found`);
                return;
            }

            this.group = linkId;
            
            this.scrollTo(link);

        },

        handleScroll () {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
            
            var sections = this.links.filter(link => {
                const element = document.getElementById(link.url);
                if (!element) {
                console.warn(`Element with id ${link.url} not found`);
                return;
                }

                const position = element.offsetTop - 150;
                return scrollPosition >= position;
            });
            
            if (!sections || sections.length == 0) {
                return;
            }

            var currentSection = sections[sections.length - 1];
            if (this.group != currentSection.id) {
                this.group = currentSection.id;
            }
        },
        
        openSocial(url, target = '_blank') {
            window.open(url, target);
        },
        
    },
        
    watch: {
        group () {
        this.drawer = false;

        // if (newValue == null || newValue == undefined) {
        //   console.warn(`Invalid link id; newValue: ${newValue}; group: ${this.group}`);
        //   return;
        // }

        // var link = this.links.find(link => link.id == newValue);
        // if (!link) {
        //   console.warn(`Link with id ${newValue} not found`);
        //   return;
        // }

        // this.scrollTo(link);
        },
    },

    mounted () {
        window.addEventListener('scroll', this.handleScroll);

        this.handleScroll();
    },

    beforeDestroy () {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>