<template>
  <v-container class="di-contact-us" style="position: relative;">

    <v-row>
      <v-col></v-col>
      <v-col cols="12" xl="8" lg="10" md="12" sm="12">
            
        <h1 class="di-section-header"> Contact Us </h1>

        <div style="min-height: 65vh;">
          <v-row>
            <v-col cols="12" md="8" sm="12">

              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.5913654533297!2d28.356185411782338!3d-25.78405754690178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e955f178961daf3%3A0x2eeac7e64bf9ad1!2sDickinson%20Marx%20Incorporated!5e0!3m2!1sen!2sza!4v1685890174176!5m2!1sen!2sza" 
                      height="600" style="border: 0; width: 100%;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
              </iframe>

            </v-col>
            
            <v-col cols="12" md="4" sm="12">
              <div class="d-flex align-start justify-center flex-column mt-8 mb-16" style="gap: 2rem;">

                <v-btn v-for="(contact, i) in contactMethods" :key="i" 
                       text color="accent" :class="{ 'text--darken-3': !$vuetify.theme.dark }"
                       @click="openSocial(contact.value, contact.target)">
                  <v-icon left>{{ contact.icon }}</v-icon>
                  <div class="d-flex flex-column ml-3 text-left">
                    <span>{{ contact.name }}</span>
                    <span v-if="contact.detail">{{ contact.detail }}</span>
                  </div>
                </v-btn>

              </div>
            </v-col>
          </v-row>
        </div>

      </v-col>
      <v-col></v-col>
    </v-row>

  </v-container>
</template>

<script>
  export default {
    name: 'di-contact-us',
    props: {
      'contact-methods': { type: Array, reqquired: true},
    },

    methods: {
      openSocial(url, target = '_blank') {
        this.$emit('open-social', url, target);
      },
    }
  }
</script>

<style lang="scss">
.di-contact-us {
  iframe {
    border-radius: 1rem;
  }
}
</style>
