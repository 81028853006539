<template>
  <v-container class="di-about-us" style="position: relative;">

    <v-row>
      <v-col v-if="!$vuetify.breakpoint.mobile"></v-col>
      <v-col :md="!$vuetify.breakpoint.mobile ? 10 : 12">
              
        <h1 class="di-section-header"> About Us </h1>

        <p>
          Established in 2022, our team provides excellent legal services locally and internationally, building long-lasting relationships with clients. We have attorneys specializing in various legal fields, offering a wide range of services.
        </p>
        <p>
          Our commitment is to provide innovative and credible strategies, meeting our clients' legal requirements. Through effective communication, including mutual objectives, budget development, and feedback sessions, we ensure our clients' expectations are met.
        </p>

        <h2>  </h2>

        <v-row>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <v-card class="di-card mb-1 flex-grow-1 d-flex flex-column">
              <v-card-text class="flex-grow-1">
                <div class="d-flex di-card-content" style="gap: 2rem;">
                  <v-img class="flex-grow-0"
                    src="../assets/vision_illustration.svg"
                    alt="vision" contain position="center center" width="100" height="100"
                  />

                  <p class="pt-3">
                    To act as a team with the unified goal of providing quality services to our clients.
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <v-card class="di-card mb-1 flex-grow-1 d-flex flex-column">
              <v-card-text class="flex-grow-1">
                <div class="d-flex di-card-content" style="gap: 2rem;">
                  <v-img class="flex-grow-0"
                    src="../assets/mission_illustration.svg"
                    alt="mission" contain position="center center" width="100" height="100"
                  />

                  <p class="pt-3">
                    To reach the best outcomes for our clients.
                  </p>
                </div>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <h2> Our Team </h2>

        <template v-for="(member, i) in team">
          <div :key="i" class="mb-12 mt-8">

            <div class="d-flex">

              <v-spacer></v-spacer>

                <v-card class="di-card m-auto text-left" :style="{ 'max-width': ($vuetify.breakpoint.mobile ? '100%' : '50%') }">
                  <v-card-text>
                    <div class="d-flex" style="gap: 1em;">
                      <v-img :src="require(`../assets/profile/${member.image}`)" alt="member image"
                              width="100" height="100" @click="openProfile(member.id)" style="cursor: pointer;"
                      />

                      <div>
                        <div class="text-h5"> {{ member.name }} </div>
                        <div class="text-h6"> {{ member.title }} </div>
                      </div>
                    </div>
                  </v-card-text>
              </v-card>

              <v-spacer></v-spacer>

            </div>

            <v-timeline
              align-top
              :dense="$vuetify.breakpoint.smAndDown"
            >
              <v-timeline-item
                v-for="(item, i) in member.timeline"
                :key="i"
                :icon="item.icon"
                fill-dot
                color="primary"
              >
                <v-card class="di-card text-left" color="hero-light-purple">
                  <v-card-title class="pa-3" style="color: var(--v-black-base);">
                    <div class="text-h6">{{ item.date }}</div>
                  </v-card-title>
                  <v-card-text class="text--primary background lighten5">
                    <p class="pt-3">
                      {{ item.description }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </div>
        </template>
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.mobile"></v-col>
    </v-row>

  </v-container>
</template>

<script>
import team from '../assets/data/team.json';

export default {
  name: 'di-about-us',

  data: () => ({
    team: team,
  }),

  methods: {
    openProfile(id) {
      this.$router.push({ name: 'profile', params: { id: id } });
    }
  }
}
</script>

<style lang="scss">

$-size: 1em;

.di-about-us {
  h2, h3 {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
  
  .di-card-content {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (min-width: 800px) {
  
    .di-card-content {
      flex-direction: row;
    }
  }
}

</style>
