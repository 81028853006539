import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

// export default new Vuetify({
// 	theme: {
// 		themes: {
// 			light: {
// 				primary: '#06d161',
// 				secondary: '#b0bec5',
// 				accent: '#8440f8',
// 			},
// 		},
// 	},
// });

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
      // themeCache: {
      //   get: (key) => localStorage.getItem(key),
      //   set: (key, value) => localStorage.setItem(key, value),
      // },
    },
    dark: true,
    themes: {
      light: {
        primary: '#06d161',
        secondary: '#b0bec5',
        accent: '#9c6fe7',
        error: colors.red.base,
        warning: colors.orange.base,
        info: colors.cyan.base,
        success: colors.green.base,
        background: '#f8f7fc',
        white: '#fff',
        black: '#303030',
        'hero-light-purple': '#e8dcff',
        'di-border-color': '#303030',
        'di-scroll-bar-bg-color': '#303030',
        'di-scroll-bar-color': '#707070',
      },
      dark: {
        primary: '#06d161',
        secondary: '#b0bec5',
        accent: '#9c6fe7',
        background: '#1e1e1e',
        white: '#fff',
        black: '#303030',
        'hero-light-purple': '#e8dcff',
        'di-border-color': '#707070',
        'di-scroll-bar-bg-color': '#303030',
        'di-scroll-bar-color': '#707070',
      },
    },
  },
});

export default vuetify;
