<template>
    <div v-if="profile" class="di-profile d-flex flex-column flex-grow-1 justify-center align-center py-4">

        <v-card class="di-card m-auto text-left mt-12" :style="{ 'max-width': ($vuetify.breakpoint.mobile ? '100%' : '50%') }">
            <v-card-text class="text-center" style="position: relative;">
                <!-- FAB -->
                <v-btn fab absolute small color="grey" style="left: 1em;"
                       @click="back" title="Back to site">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

                <div class="d-flex di-card-content" style="gap: 1em;">
                    <v-img :src="require(`../assets/profile/${profile.image}`)" alt="profile image"
                            width="150" height="150"
                    />

                    <div>
                        <div class="text-h5"> {{ profile.name }} </div>
                        <div class="text-h6"> {{ profile.title }} </div>
                    </div>
                </div>

                <v-row>
                    <v-col cols="12" class="d-flex justify-center">
                        <div class="d-flex align-start justify-center flex-column flex-grow-0 mt-4 mb-8" style="gap: 2rem;">
                            <v-btn v-for="(contact, i) in contacts.filter(x=> x.isSocial == false)" :key="i" 
                                text color="accent" :class="{ 'text--darken-3': !$vuetify.theme.dark }"
                                @click="openSocial(contact.value, contact.target)">
                                <v-icon left>{{ contact.icon }}</v-icon>
                                <div class="d-flex flex-column ml-3 text-left">
                                    <span>{{ contact.name }}</span>
                                    <span v-if="contact.detail">{{ contact.detail }}</span>
                                </div>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>

                <v-btn rounded color="primary darken-1" class="my-4"
                       @click="saveContact()">
                    <v-icon left>mdi-download</v-icon>
                    <span>Save Contact</span>
                </v-btn>

            </v-card-text>
        </v-card>

        <div class="text-h5 my-6"> Social Media </div>

        <v-card class="di-card">
            <v-card-text class="di-card-content pt-4">
                <v-row>
                    <v-col cols="12">
    
                        <div class="d-flex align-start justify-center mt-4 mb-4" style="gap: 2rem;">
                            <v-btn v-for="(contact, i) in contacts.filter(x=> x.isSocial)" :key="i" 
                                text color="accent" :class="{ 'text--darken-3': !$vuetify.theme.dark }"
                                @click="openSocial(contact.value, contact.target)">
                                <v-icon left>{{ contact.icon }}</v-icon>
                                <div class="d-flex flex-column ml-3 text-left">
                                    <span>{{ contact.name }}</span>
                                    <span v-if="contact.detail">{{ contact.detail }}</span>
                                </div>
                            </v-btn>
                        </div>
    
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>

    </div>
</template>

<script>

import team from '../assets/data/team.json'
import contacts from '../assets/data/contacts.json'

export default {
    name: 'di-profile',
    props: ['id'],

    data: () => ({
        team: team,
        contacts: contacts,

        profile: null,
    }),

    methods: {
        openSocial (url, target) {
            window.open(url, target);
        },

        back () {
            return window.history.length > 2
                            ? this.$router.go(-1)
                            : this.$router.push({ name: 'home' });
        },

        saveContact () {
            // Get the contact information from the website
            var contact = {
                cardname: this.profile.name,
                fullname: `${this.profile.lastName};${this.profile.firstName}`,
                role: this.profile.title,
                office: contacts.find(x=> x.name == "Office").detail,
                whatsapp: contacts.find(x=> x.name == "Whatsapp").detail,
                email: contacts.find(x=> x.name == "Email").detail,
                weburl: this.profile.weburl,
                address: ";Tijger Vallei Unit 6 Ground Floor;78 Pony Street;Pretoria;Gauteng;0181;South Africa",
                geo: "geo:-25.784014073873298, 28.358754967934146",
            };

            // create a vcard file
            var vcard = "BEGIN:VCARD\nVERSION:4.0" 
                        + "\nFN:" + contact.cardname
                        + "\nN:" + contact.fullname
                        + "\nORG:Dickinson Marx Incorperated" 
                        + "\nTITLE:" + contact.role 
                        + "\nTEL;TYPE=work,voice:" + contact.office 
                        + "\nTEL;TYPE=cell,voice:" + contact.whatsapp 
                        + "\nEMAIL:" + contact.email 
                        + "\nURL:" + contact.weburl 
                        + "\nADR:" + contact.address 
                        + "\nGEO:" + contact.geo 
                        + "\nEND:VCARD";

            var blob = new Blob([vcard], { type: "text/vcard" });
            var url = URL.createObjectURL(blob);
            
            const newLink = document.createElement('a');
            newLink.download = contact.cardname + ".vcf";
            newLink.textContent = contact.cardname;
            newLink.href = url;
            
            newLink.click();
        },
    },

    mounted () {
        this.profile = this.team.find(item => item.id == this.id);
        if (!this.profile) {
            // this.$router.go('/home');
            return;
        }
    },
}
</script>

<style lang="scss">

.di-profile {
    background-color: var(--v-background-lighten1);
    
    background: repeating-linear-gradient(
      to right,
      var(--v-background-base) 0px,
      var(--v-background-base) 1px,
      var(--v-background-lighten1) 2px,
      var(--v-background-lighten1) 100px,
    );

    .di-card-content {
        position: relative;
        padding-top: 8em;

        flex-direction: row;
        align-items: center;
        justify-content: center;

        .v-image {
            position: absolute;
            top: -5em;
            z-index: 999; 
        }
    }

    @media screen and (min-width: 800px) {
  
        .di-card-content {
            min-width: 500px;
            flex-direction: column;
        }
    }

}
</style>
